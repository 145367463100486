<template>
  <v-row class="ma-0">
    <v-col>
      <v-card elevation="5" max-width="570" class="mx-auto pa-15">
        <v-card-title class="pa-0 d-flex flex-column align-center">
          <img src="@/assets/img/invite.svg" alt="lock" />
          <v-row>
            <v-col cols="9" class="mx-auto">
              <div class="text-h5 font-weight-bold mt-7 text-break text-center">Sorry, but this invitation is not more valid</div>
              <div class="body-1 mb-4 mt-2 text-break text-center opasity--text">The invitation has been expired.</div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions class="flex-column px-15 mt-5">
          <v-btn to="/login" text class="text-capitalize secondary--text opasity--text">
            <v-icon left>mdi-arrow-left</v-icon> Back to Sign In
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
};
</script>
